import { isPast } from "date-fns";
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useApiCall from "../../../../hooks/useApiCall";
import useErrors from "../../../../hooks/useErrors";
import cuponsService from "../../../../services/cuponsService";
import floatToCurrency from "../../../../utils/floatToCurrency";
import formatCurrency from "../../../../utils/formatCurrency";
import onlyNumbers from "../../../../utils/onlyNumbers";
import parseCurrencyStringToFloat from "../../../../utils/parseCurrencyStringToFloat";
import { CupomFromApiObject, PlanKindTypes } from "../../types";

interface UseEditModalI {
  loadCupons: () => Promise<void>;
  setEditModalShow: Dispatch<SetStateAction<boolean>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  cupomObjectBeingEditted: CupomFromApiObject;
}

export default function useEditModal({ loadCupons, setEditModalShow, setIsLoading, cupomObjectBeingEditted }: UseEditModalI) {
  const [cupomPlanKind, setCupomPlanKind] = useState<PlanKindTypes>({ value: '', label: 'Selecione um tipo de plano' });
  const [cupomPlanValue, setCupomPlanValue] = useState('');
  const [cupomPlanTrial, setCupomPlanTrial] = useState<number | string>('');
  const [cupomName, setCupomName] = useState('');
  const [companyAssociated, setCompanyAssociated] = useState({ value: '', label: 'Nenhuma empresa' });

  const [expires, setExpires] = useState(false);
  const [expirationDate, setExpirationDate] = useState('');

  const [willLimitUsage, setWillLimitUsage] = useState(false);
  const [useLimit, setUseLimit] = useState<string | number>('');
  const [considerTestUntilTrialDays, setConsiderTestUntilTrialDays] = useState(true);

  const {
    setError, removeError, getErrorMessageByFieldName, errors,
  } = useErrors();

  const { apiCall } = useApiCall();

  function handleCupomPlanValueChange(event: ChangeEvent<HTMLInputElement>) {
    setCupomPlanValue(formatCurrency(event.target.value));
    if(parseCurrencyStringToFloat(formatCurrency(event.target.value)) as number >= 49.9) {
      setError({ field: 'cupomPlanValue', message: 'O valor padrão é de R$ 49,90. Insira um valor menor que este' })
    } else {
      removeError('cupomPlanValue')
    }
  }

  function handleCupomPlanTrialChange(event: ChangeEvent<HTMLInputElement>) {
    setCupomPlanTrial(onlyNumbers(event.target.value));
    if (onlyNumbers(event.target.value) <= 7) {
      setError({ field: 'cupomPlanTrial', message: 'O período padrão é de 7 dias. Insira um período maior que este' })
    } else {
      removeError('cupomPlanTrial')
    }
  }

  function handleCupomNameChange(event: ChangeEvent<HTMLInputElement>) {
    setCupomName(event.target.value);
  }

  function handleExpirationDateChange(event: ChangeEvent<HTMLInputElement>) {
    const selectedDate = new Date(event.target.value);
    if (isPast(selectedDate)) {
      toast.warn('A data de vencimento não pode ser uma data passada');
      return;
    }
    setExpirationDate(event.target.value)
  }

  function handleUseLimitChange(event: ChangeEvent<HTMLInputElement>) {
    setUseLimit(onlyNumbers(event.target.value));
  }

  const editCupom = useCallback(async () => {
    const valueAndTrialByPlanKindLiterals: { [key: string]: { planValue: null | number, planTrialDays: null | number }} = {
      free: { planValue: null, planTrialDays: null },
      trial: { planValue: null, planTrialDays: Number(cupomPlanTrial) },
      trial_no_subscription: { planValue: null, planTrialDays: Number(cupomPlanTrial) },
      discount: { planValue: parseCurrencyStringToFloat(cupomPlanValue), planTrialDays: null },
      discount_no_trial: { planValue: parseCurrencyStringToFloat(cupomPlanValue), planTrialDays: null },
      trial_discount: { planValue: parseCurrencyStringToFloat(cupomPlanValue), planTrialDays: Number(cupomPlanTrial) },
    }

    await apiCall({
      apiToCall: cuponsService.updateCupom,
      queryParams: { id: cupomObjectBeingEditted.id },
      onStartLoad: () => { setIsLoading(true) },
      reqBody: JSON.stringify({
        planKind: cupomPlanKind.value,
        name: cupomObjectBeingEditted.name,
        plan_id: cupomObjectBeingEditted.plan_id,
        expiresAt: expires ? new Date(expirationDate) : null,
        useLimit: willLimitUsage ? useLimit : null,
        ...valueAndTrialByPlanKindLiterals[cupomPlanKind.value],
        ...(cupomPlanKind.value.includes('trial') ? { considerTestUntilTrialDays } : {}),
      }),
      actionAfterResponse: (response) => {
        if (response.success) {
          toast.success('Cupom adicionado com sucesso!');
          setEditModalShow(false);
          loadCupons()
          return;
        }
        toast.error('Não foi possível adicionar o cupom. Por favor, tente novamente');
        setIsLoading(false);
      },
      catchMessage: 'Não foi possível adicionar o cupom. Por favor, tente novamente',
      catchAction: () => setIsLoading(false),
    })
  }, [apiCall, considerTestUntilTrialDays, cupomObjectBeingEditted?.id, cupomObjectBeingEditted?.name, cupomObjectBeingEditted?.plan_id, cupomPlanKind?.value, cupomPlanTrial, cupomPlanValue, expirationDate, expires, loadCupons, setEditModalShow, setIsLoading, useLimit, willLimitUsage])

  const loadCupomDetails = useCallback(async () => {
    const planKindObjectLiterals: { [key: string]: { value: string, label: string }} = {
      free: { value: 'free', label: 'Acesso grátis'},
      trial: { value: 'trial', label: 'Período grátis'},
      trial_no_subscription: { value: 'trial_no_subscription', label: 'Período grátis (sem cobrança)'},
      discount: { value: 'discount', label: 'Desconto'},
      trial_discount: { value: 'trial_discount', label: 'Período grátis. Após, desconto' },
    }

    setCupomName(cupomObjectBeingEditted.name);
    setCompanyAssociated({ value: cupomObjectBeingEditted.company?.id || '', label: cupomObjectBeingEditted.company?.nome_fantasia || 'Nenhuma empresa' });
    setCupomPlanKind(planKindObjectLiterals[cupomObjectBeingEditted.planKind]);
    setCupomPlanValue(floatToCurrency(cupomObjectBeingEditted.planValue) || '');
    setCupomPlanTrial(cupomObjectBeingEditted.planTrialDays || '');
    setExpires(!!cupomObjectBeingEditted.expiresAt);
    // setExpirationDate(cupomObjectBeingEditted.expiresAt ? new Date(cupomObjectBeingEditted.expiresAt).toISOString().split('T')[0] : '');
    setWillLimitUsage(!!cupomObjectBeingEditted.useLimit);
    setUseLimit(cupomObjectBeingEditted.useLimit || 0);
    setConsiderTestUntilTrialDays(cupomObjectBeingEditted?.considerTestUntilTrialDays || false);
  }, [cupomObjectBeingEditted?.company?.id, cupomObjectBeingEditted?.company?.nome_fantasia, cupomObjectBeingEditted?.considerTestUntilTrialDays, cupomObjectBeingEditted?.expiresAt, cupomObjectBeingEditted?.name, cupomObjectBeingEditted?.planKind, cupomObjectBeingEditted?.planTrialDays, cupomObjectBeingEditted?.planValue, cupomObjectBeingEditted?.useLimit]);

  useEffect(() => { loadCupomDetails() }, [loadCupomDetails]);

  const isCupomFormValid = errors.length === 0 && !!cupomPlanKind?.value && ((cupomPlanKind?.value === 'free') || (cupomPlanKind?.value === 'discount' && !!cupomPlanValue) || (cupomPlanKind?.value === 'trial' && !!cupomPlanTrial) || (cupomPlanKind?.value === 'trial_discount' && !!cupomPlanValue && !!cupomPlanTrial) || (cupomPlanKind.value === 'trial_no_subscription' && !!cupomPlanTrial) || (cupomPlanKind.value === 'discount_no_trial'));

  return {
    editCupom,
    cupomPlanKind,
    cupomPlanValue,
    cupomPlanTrial,
    cupomName,
    handleCupomPlanValueChange,
    handleCupomPlanTrialChange,
    setCupomPlanKind,
    getErrorMessageByFieldName,
    isCupomFormValid,
    handleCupomNameChange,
    companyAssociated,
    handleExpirationDateChange,
    expirationDate,
    expires,
    setExpires,
    setWillLimitUsage,
    willLimitUsage,
    handleUseLimitChange,
    useLimit,
    considerTestUntilTrialDays,
    setConsiderTestUntilTrialDays,
  }
}
