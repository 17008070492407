/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { motion } from 'framer-motion';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import Select from 'react-select';
import { CustomStyle } from '../../../../../components/CustomSelectStyle';
import FilterRadioButton from '../../../../../components/FilterRadioButtons';
import { FilterRadioButtonsContainer } from '../../../../../components/FilterRadioButtonsContainer';
import FormGroup from '../../../../../components/FormGroup';
import Input from '../../../../../components/Input';
import { SecondaryButton } from '../../../../../components/SecondaryButton';
import { GetErrorMessageByFieldNameType } from '../../../../../hooks/useErrors';
import { AsideContainer, Group, Container as StyledContainer } from '../styles';
import { AuthenticationTypeTypes, PlanKindTypes } from '../types';

interface ComplementaryDataInterface {
  monthlyFee: string;
  handleMonthlyFeeChange: (event: ChangeEvent<HTMLInputElement>) => void;
  contractDate: string;
  handleContractDateChange: (event: ChangeEvent<HTMLInputElement>) => void;
  getErrorMessageByFieldName: GetErrorMessageByFieldNameType;
  startDate: string;
  handleStartDateChange: (event: ChangeEvent<HTMLInputElement>) => void;
  authenticationType: AuthenticationTypeTypes;
  setAuthenticationType: Dispatch<SetStateAction<AuthenticationTypeTypes>>;
  isReadOnly: boolean;
  setIsReadOnly: Dispatch<SetStateAction<boolean>>;
  loginEmail: string;
  handleLoginEmailChange: (event: ChangeEvent<HTMLInputElement>) => void;
  edit: boolean;
  password: string;
  handlePasswordChange: (event: ChangeEvent<HTMLInputElement>) => void;
  passwordConfirmation: string;
  handlePasswordConfirmationChange: (event: ChangeEvent<HTMLInputElement>) => void;
  setCuponsModalShow: Dispatch<SetStateAction<boolean>>;
  setPlanKind: Dispatch<SetStateAction<PlanKindTypes>>;
  planValue: string;
  handlePlanValueChange: (event: ChangeEvent<HTMLInputElement>) => void;
  planKind: PlanKindTypes;
  planTrial: number | string;
  handlePlanTrialChange: (event: ChangeEvent<HTMLInputElement>) => void;
  kindOfCompany: 'default' | 'seller';
  setShowAtCompaniesListAppRegister: Dispatch<SetStateAction<boolean>>;
  showAtCompaniesListAppRegister: boolean;
  setViewByAssociatedCompanies: Dispatch<SetStateAction<boolean>>;
  viewByAssociatedCompanies: boolean;
}

export default function ComplementaryData({
  monthlyFee,
  handleMonthlyFeeChange,
  contractDate,
  handleContractDateChange,
  getErrorMessageByFieldName,
  startDate,
  handleStartDateChange,
  authenticationType,
  setAuthenticationType,
  isReadOnly,
  setIsReadOnly,
  loginEmail,
  handleLoginEmailChange,
  edit,
  password,
  handlePasswordChange,
  passwordConfirmation,
  handlePasswordConfirmationChange,
  setCuponsModalShow,
  setPlanKind,
  planValue,
  handlePlanValueChange,
  planKind,
  planTrial,
  handlePlanTrialChange,
  kindOfCompany,
  setShowAtCompaniesListAppRegister,
  showAtCompaniesListAppRegister,
  setViewByAssociatedCompanies,
  viewByAssociatedCompanies,
}: ComplementaryDataInterface) {
  const authenticationOptions = [
    { value: 'cpf', label: 'CPF' },
    { value: 'email', label: 'E-mail' },
    { value: 'registrationNumber', label: 'Número de matrícula' },
    { value: 'randomCode', label: 'Código de identificação' },
  ];

  const planOptions = [
    { value: 'free', label: 'Acesso grátis' },
    { value: 'discount', label: 'Desconto na mensalidade' },
    { value: 'discount_no_trial', label: 'Desconto na mensalidade (Sem padrão de 7 dias grátis - Cobra na hora)' },
    { value: 'trial', label: 'Período de teste grátis' },
    { value: 'trial_no_subscription', label: 'Período de teste grátis (Sem cobrança automática)' },
    { value: 'trial_discount', label: 'Período de teste grátis. Após, desconto na mensalidade' },
  ];

  return (
    <StyledContainer>
      <div className="card-title">
        Dados complementares
      </div>

      <AsideContainer>
        {!isReadOnly && (
            <Group>
              <div className="title">
                Forma de autenticação
              </div>
              <Select
                value={{ value: authenticationType?.value, label: authenticationType?.label }}
                options={authenticationOptions}
                onChange={(opt) => {
                  setAuthenticationType({ value: opt!.value, label: opt!.label });
                }}
                styles={CustomStyle}
                classNamePrefix="react-select"
                className="react-select-container"
              />
            </Group>
        )}

        <Group>
          <div className="title">
            Método de aplicação do benefício
          </div>
          <FilterRadioButtonsContainer>
            <FilterRadioButton
              onClick={() => setIsReadOnly(false)}
              selected={!isReadOnly}
            >
              Identificação única
            </FilterRadioButton>
            <FilterRadioButton
              onClick={() => setIsReadOnly(true)}
              selected={isReadOnly}
            >
              Cupom geral
            </FilterRadioButton>

            {isReadOnly && (
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ delay: 0.1, type: 'tween' }}
              >
                <SecondaryButton
                  onClick={() => setCuponsModalShow(true)}
                  style={{ marginLeft: '24px'}}
                >
                  {edit ? 'Visualizar cupons' : 'Gerenciar cupons'}
                </SecondaryButton>
              </motion.div>
            )}
          </FilterRadioButtonsContainer>
        </Group>
      </AsideContainer>

      <AsideContainer>
        {isReadOnly && (
          <Group>
            <div className="title">
              Permitir que apareça ao registrar-se no App?
            </div>
            <FilterRadioButtonsContainer>
              <FilterRadioButton
                onClick={() => setShowAtCompaniesListAppRegister(true)}
                selected={showAtCompaniesListAppRegister}
              >
                Sim
              </FilterRadioButton>
              <FilterRadioButton
                onClick={() => setShowAtCompaniesListAppRegister(false)}
                selected={!showAtCompaniesListAppRegister}
              >
                Não
              </FilterRadioButton>
          </FilterRadioButtonsContainer>
          </Group>
        )}

        {(!isReadOnly && kindOfCompany === 'seller') && (
          <Group>
            <div className="title">
              Diferenciará os usuários por empresa associada?
            </div>
            <FilterRadioButtonsContainer>
              <FilterRadioButton
                onClick={() => setViewByAssociatedCompanies(true)}
                selected={viewByAssociatedCompanies}
              >
                Sim
              </FilterRadioButton>
              <FilterRadioButton
                onClick={() => setViewByAssociatedCompanies(false)}
                selected={!viewByAssociatedCompanies}
              >
                Não
              </FilterRadioButton>
          </FilterRadioButtonsContainer>
        </Group>
        )}
      </AsideContainer>

      {!isReadOnly && (
        <AsideContainer>
          <Group>
            <div className="title">
              Tipo do plano
            </div>
            <Select
              value={{ value: planKind?.value, label: planKind?.label }}
              options={planOptions}
              onChange={(opt) => {
                setPlanKind({ value: opt!.value, label: opt!.label });
              }}
              styles={CustomStyle}
              classNamePrefix="react-select"
              className="react-select-container"
            />
          </Group>

          {(planKind.value.includes('discount')) && (
            <Group>
              <div className="title">
                Valor do plano
              </div>
              <FormGroup error={getErrorMessageByFieldName('planValue')}>
                <Input
                  value={planValue}
                  onChange={handlePlanValueChange}
                  placeholder="Novo valor do plano com a aplicação do desconto"
                  error={getErrorMessageByFieldName('planValue')}
                />
              </FormGroup>
            </Group>
          )}

          {(planKind.value === 'trial' || planKind.value === 'trial_discount' || planKind.value === 'trial_no_subscription') && (
            <Group>
              <div className="title">
                Período de teste grátis (em dias)
              </div>
              <FormGroup error={getErrorMessageByFieldName('planTrial')}>
                <Input
                  value={planTrial}
                  onChange={handlePlanTrialChange}
                  placeholder="Período em dias de teste grátis"
                  error={getErrorMessageByFieldName('planTrial')}
                />
              </FormGroup>
            </Group>
          )}
        </AsideContainer>
      )}

      <AsideContainer>
        <Group>
          <div className="title">
            E-mail de login (Principal)
          </div>
          <FormGroup error={getErrorMessageByFieldName('loginEmail')}>
            <Input
              value={loginEmail}
              onChange={handleLoginEmailChange}
              placeholder="E-mail para acessar o sistema administrativo"
              error={getErrorMessageByFieldName('loginEmail')}
              disabled={edit}
            />
          </FormGroup>
        </Group>
        {!edit && (
          <>
            <Group>
            <div className="title">
              Senha para primeiro acesso
            </div>
            <FormGroup error={getErrorMessageByFieldName('password')}>
              <Input
                type='password'
                value={password}
                onChange={handlePasswordChange}
                placeholder="Senha provisória"
                error={getErrorMessageByFieldName('password')}
              />
            </FormGroup>
            </Group>
            <Group>
              <div className="title">
                Confirmação de senha
              </div>
              <FormGroup error={getErrorMessageByFieldName('passwordConfirmation')}>
                <Input
                  type='password'
                  value={passwordConfirmation}
                  onChange={handlePasswordConfirmationChange}
                  placeholder="Confirme a senha"
                  error={getErrorMessageByFieldName('passwordConfirmation')}
                />
              </FormGroup>
            </Group>
          </>
        )}
      </AsideContainer>

      <AsideContainer>
        <Group>
          <div className="title">
            Valor/mês
          </div>
          <FormGroup>
            <Input
              value={monthlyFee}
              onChange={handleMonthlyFeeChange}
              placeholder="Valor acordado por mês"
            />
          </FormGroup>
        </Group>
        <Group>
          <div className="title">
            Data início contrato vigente
          </div>
          <FormGroup error={getErrorMessageByFieldName('contractDate')}>
            <Input
              type="date"
              value={contractDate}
              onChange={handleContractDateChange}
              error={getErrorMessageByFieldName('contractDate')}
            />
          </FormGroup>
        </Group>
        <Group>
          <div className="title">
            Data do lançamento
          </div>
          <FormGroup error={getErrorMessageByFieldName('startDate')}>
            <Input
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              error={getErrorMessageByFieldName('startDate')}
            />
          </FormGroup>
        </Group>
      </AsideContainer>
    </StyledContainer>
  );
}
