import { isPast } from "date-fns";
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useState } from "react";
import { toast } from "react-toastify";
import useApiCall from "../../../../hooks/useApiCall";
import useErrors from "../../../../hooks/useErrors";
import cuponsService from "../../../../services/cuponsService";
import formatCurrency from "../../../../utils/formatCurrency";
import onlyNumbers from "../../../../utils/onlyNumbers";
import parseCurrencyStringToFloat from "../../../../utils/parseCurrencyStringToFloat";
import { PlanKindTypes } from "../../types";

interface UseAddModalI {
  loadCupons: () => Promise<void>;
  setAddCupomModalShow: Dispatch<SetStateAction<boolean>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export default function useAddModal({ loadCupons, setAddCupomModalShow, setIsLoading }: UseAddModalI) {
  const [cupomPlanKind, setCupomPlanKind] = useState<PlanKindTypes>({ value: '', label: 'Selecione um tipo de plano' });
  const [cupomPlanValue, setCupomPlanValue] = useState('');
  const [cupomPlanTrial, setCupomPlanTrial] = useState<number | string>('');
  const [cupomName, setCupomName] = useState('');
  const [companyAssociated, setCompanyAssociated] = useState({ value: '', label: 'Nenhuma empresa' });
  const [willLimitUsage, setWillLimitUsage] = useState(false);
  const [useLimit, setUseLimit] = useState<string | number>('');
  const [considerTestUntilTrialDays, setConsiderTestUntilTrialDays] = useState(true);

  const [expires, setExpires] = useState(false);
  const [expirationDate, setExpirationDate] = useState('');

  const {
    setError, removeError, getErrorMessageByFieldName, errors,
  } = useErrors();

  const { apiCall } = useApiCall();

  function handleCupomPlanValueChange(event: ChangeEvent<HTMLInputElement>) {
    setCupomPlanValue(formatCurrency(event.target.value));
    if(parseCurrencyStringToFloat(formatCurrency(event.target.value)) as number >= 49.9) {
      setError({ field: 'cupomPlanValue', message: 'O valor padrão é de R$ 49,90. Insira um valor menor que este' })
    } else {
      removeError('cupomPlanValue')
    }
  }

  function handleCupomPlanTrialChange(event: ChangeEvent<HTMLInputElement>) {
    setCupomPlanTrial(onlyNumbers(event.target.value));
    if (onlyNumbers(event.target.value) <= 7) {
      setError({ field: 'cupomPlanTrial', message: 'O período padrão é de 7 dias. Insira um período maior que este' })
    } else {
      removeError('cupomPlanTrial')
    }
  }

  function handleCupomNameChange(event: ChangeEvent<HTMLInputElement>) {
    setCupomName(event.target.value);
  }

  function handleExpirationDateChange(event: ChangeEvent<HTMLInputElement>) {
    const selectedDate = new Date(event.target.value);
    if (isPast(selectedDate)) {
      toast.warn('A data de vencimento não pode ser uma data passada');
      return;
    }
    setExpirationDate(event.target.value)
  }

  function handleUseLimitChange(event: ChangeEvent<HTMLInputElement>) {
    setUseLimit(onlyNumbers(event.target.value));
  }

  const addCupom = useCallback(async () => {
    const valueAndTrialByPlanKindLiterals: { [key: string]: { planValue: null | number, planTrialDays: null | number }} = {
      free: { planValue: null, planTrialDays: null },
      trial: { planValue: null, planTrialDays: Number(cupomPlanTrial) },
      trial_no_subscription: { planValue: null, planTrialDays: Number(cupomPlanTrial) },
      discount: { planValue: parseCurrencyStringToFloat(cupomPlanValue), planTrialDays: null },
      discount_no_trial: { planValue: parseCurrencyStringToFloat(cupomPlanValue), planTrialDays: null },
      trial_discount: { planValue: parseCurrencyStringToFloat(cupomPlanValue), planTrialDays: Number(cupomPlanTrial) },
    }

    await apiCall({
      apiToCall: cuponsService.createCupom,
      onStartLoad: () => { setIsLoading(true) },
      reqBody: JSON.stringify({
        name: cupomName,
        planKind: cupomPlanKind.value,
        ...valueAndTrialByPlanKindLiterals[cupomPlanKind.value],
        ...(companyAssociated.value ? { companyId: companyAssociated.value } : {}),
        ...(expires ? { expiresAt: new Date(expirationDate) } : {}),
        ...(willLimitUsage ? { useLimit: useLimit } : {}),
        ...(cupomPlanKind.value.includes('trial') ? { considerTestUntilTrialDays } : {})
      }),
      actionAfterResponse: (response) => {
        if (response.success) {
          toast.success('Cupom adicionado com sucesso!');
          setAddCupomModalShow(false);
          setCupomName('');
          setCupomPlanKind({ value: '', label: 'Selecione um tipo de plano' });
          setCupomPlanValue('');
          setCupomPlanTrial('')
          setCompanyAssociated({ value: '', label: 'Nenhuma empresa' })
          setExpires(false);
          setExpirationDate('');
          loadCupons();
          return;
        }
        toast.error('Não foi possível adicionar o cupom. Por favor, tente novamente');
        setIsLoading(false);
      },
      catchMessage: 'Não foi possível adicionar o cupom. Por favor, tente novamente',
      catchAction: () => setIsLoading(false),
    })
  }, [apiCall, companyAssociated.value, considerTestUntilTrialDays, cupomName, cupomPlanKind.value, cupomPlanTrial, cupomPlanValue, expirationDate, expires, loadCupons, setAddCupomModalShow, setIsLoading, useLimit, willLimitUsage])

  const isCupomFormValid = errors.length === 0 && !!cupomPlanKind.value && !!cupomName && ((cupomPlanKind.value === 'free') || (cupomPlanKind.value === 'discount' && !!cupomPlanValue) || (cupomPlanKind.value === 'trial' && !!cupomPlanTrial) || (cupomPlanKind.value === 'trial_no_subscription' && !!cupomPlanTrial) || (cupomPlanKind.value === 'trial_discount' && !!cupomPlanValue && !!cupomPlanTrial) || (cupomPlanKind.value === 'discount_no_trial'));

  return {
    addCupom,
    cupomPlanKind,
    cupomPlanValue,
    cupomPlanTrial,
    cupomName,
    handleCupomPlanValueChange,
    handleCupomPlanTrialChange,
    setCupomPlanKind,
    getErrorMessageByFieldName,
    isCupomFormValid,
    handleCupomNameChange,
    companyAssociated,
    setCompanyAssociated,
    handleExpirationDateChange,
    expirationDate,
    expires,
    setExpires,
    setWillLimitUsage,
    willLimitUsage,
    handleUseLimitChange,
    useLimit,
    considerTestUntilTrialDays,
    setConsiderTestUntilTrialDays,
  }
}
