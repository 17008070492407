import { isPast } from "date-fns";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useApiCall from "../../../../../../hooks/useApiCall";
import useErrors from "../../../../../../hooks/useErrors";
import cuponsService from "../../../../../../services/cuponsService";
import formatCurrency from "../../../../../../utils/formatCurrency";
import onlyNumbers from "../../../../../../utils/onlyNumbers";
import parseCurrencyStringToFloat from "../../../../../../utils/parseCurrencyStringToFloat";
import { CupomObject, FullCupomFromApiObject, PlanKindTypes } from "../../types";

export default function useCupons() {
  const [cuponsModalShow, setCuponsModalShow] = useState(false);
  const [cupons, setCupons] = useState<CupomObject[]>([]);
  const [cupomPlanKind, setCupomPlanKind] = useState<PlanKindTypes>({ value: '', label: 'Selecione um tipo de plano' });
  const [cupomPlanValue, setCupomPlanValue] = useState('');
  const [cupomPlanTrial, setCupomPlanTrial] = useState<number | string>('');
  const [cupomName, setCupomName] = useState('');
  const [cuponsOptions, setCuponsOptions] = useState<FullCupomFromApiObject[]>([]);
  const [associatedCuponsIds, setAssociatedCuponsIds] = useState<string[]>([]);
  const [isGettingCuponsOptions, setIsGettingCuponsOptions] = useState(false);
  const [willLimitUsage, setWillLimitUsage] = useState(false);
  const [useLimit, setUseLimit] = useState<string | number>('');
  const [considerTestUntilTrialDays, setConsiderTestUntilTrialDays] = useState(true);

  const [expires, setExpires] = useState(false);
  const [expirationDate, setExpirationDate] = useState('');

  const {
    setError, removeError, getErrorMessageByFieldName, errors,
  } = useErrors();
  const { apiCall } = useApiCall();

  function handleCupomPlanValueChange(event: ChangeEvent<HTMLInputElement>) {
    setCupomPlanValue(formatCurrency(event.target.value));
    if(parseCurrencyStringToFloat(formatCurrency(event.target.value)) as number >= 49.9) {
      setError({ field: 'cupomPlanValue', message: 'O valor padrão é de R$ 49,90. Insira um valor menor que este' })
    } else {
      removeError('cupomPlanValue')
    }
  }

  function handleCupomPlanTrialChange(event: ChangeEvent<HTMLInputElement>) {
    setCupomPlanTrial(onlyNumbers(event.target.value));
    if (onlyNumbers(event.target.value) <= 7) {
      setError({ field: 'cupomPlanTrial', message: 'O período padrão é de 7 dias. Insira um período maior que este' })
    } else {
      removeError('cupomPlanTrial')
    }
  }

  function handleCupomNameChange(event: ChangeEvent<HTMLInputElement>) {
    setCupomName(event.target.value);
  }

  function handleExpirationDateChange(event: ChangeEvent<HTMLInputElement>) {
    const selectedDate = new Date(event.target.value);
    if (isPast(selectedDate)) {
      toast.warn('A data de vencimento não pode ser uma data passada');
      return;
    }
    setExpirationDate(event.target.value)
  }

  function handleUseLimitChange(event: ChangeEvent<HTMLInputElement>) {
    setUseLimit(onlyNumbers(event.target.value));
  }

  const addCupom = useCallback((cupom: CupomObject) => {
    const cupomAlreadyExists = cupons.some((cp) => cp.cupomName === cupom.cupomName);
    if (cupomAlreadyExists) {
      toast.error('Este cupom já foi adicionado!');
      return;
    }

    setCupons((prevState) => [...prevState, cupom])
    setCupomName('');
    setCupomPlanTrial('');
    setCupomPlanValue('');
    setCupomPlanKind({ value: '', label: 'Selecione um tipo de plano' });
    setWillLimitUsage(false);
    setUseLimit('');
    setConsiderTestUntilTrialDays(true);

    toast.success('Cupom adicionado com sucesso!');
  }, [cupons]);

  const removeCupom = useCallback((cupom: string) => {
    setCupons((prevState) => prevState.filter((cp) => cp.cupomName !== cupom))
  }, []);

  const loadCuponsOptions = useCallback(async () => {
    await apiCall({
      apiToCall: cuponsService.getCuponsList,
      onStartLoad: () => setIsGettingCuponsOptions(true),
      onEndLoad: () => setIsGettingCuponsOptions(false),
      actionAfterResponse: (response: { cupons: FullCupomFromApiObject[] }) => {
        const cuponsList: FullCupomFromApiObject[] = response.cupons;
        if (!cuponsList) {
          toast.error(`Não foi possível carregar a lista de cupons`);
          return;
        }
        setCuponsOptions(cuponsList.filter((cp) => !cp.company));
      }
    });
  }, [apiCall]);

  const isCupomFormValid = errors.length === 0 && !!cupomPlanKind.value && !!cupomName && ((cupomPlanKind.value === 'free') || (cupomPlanKind.value === 'discount' && !!cupomPlanValue) || (cupomPlanKind.value === 'trial' && !!cupomPlanTrial) || (cupomPlanKind.value === 'trial_discount' && !!cupomPlanValue && !!cupomPlanTrial) || (cupomPlanKind.value === 'discount_no_trial'));

  useEffect(() => { loadCuponsOptions() }, [loadCuponsOptions]);

  return {
    setCuponsModalShow,
    cuponsModalShow,
    cupons,
    addCupom,
    removeCupom,
    cupomPlanKind,
    cupomPlanValue,
    cupomPlanTrial,
    cupomName,
    handleCupomPlanValueChange,
    handleCupomPlanTrialChange,
    handleCupomNameChange,
    getErrorMessageByFieldNameCupomForm: getErrorMessageByFieldName,
    isCupomFormValid,
    setCupomPlanKind,
    setCupons,
    setCupomName,
    setCupomPlanTrial,
    setCupomPlanValue,
    isGettingCuponsOptions,
    cuponsOptions,
    associatedCuponsIds,
    setAssociatedCuponsIds,
    expires,
    setExpires,
    handleExpirationDateChange,
    expirationDate,
    willLimitUsage,
    setWillLimitUsage,
    handleUseLimitChange,
    useLimit,
    considerTestUntilTrialDays,
    setConsiderTestUntilTrialDays,
  }
}
