import Loader from "../../components/Loader";
import NoData from "../../components/NoData";
import Sidebar from "../../components/Sidebar";
import Transitions from "../../components/Transition";
import AddCupomModal from "./components/AddCupomModal";
import ChangeStatusModal from "./components/ChangeStatusModal";
import DeleteModal from "./components/DeleteModal";
import EditModal from "./components/EditModal";
import ListHeader from "./components/header";
import List from "./components/list";
import SearchAtPage from "./components/searchAtPage";
import useCupons from "./useCupons";

export default function Cupons() {
  const {
    fullCupons,
    filteredCupons,
    isLoading,
    doesListApiHasError,
    searchTerm,
    handleChangeSearchTerm,
    handlePageChange,
    pagesQuantity,
    currentPage,
    downloadExcel,
    setAddCupomModalShow,
    handleOpenEditModal,
    handleOpenDeleteModal,
    handleTryAgain,
    addCupomModalShow,
    editCupomModalShow,
    setEditModalShow,
    cupomObjectBeingEditted,
    setIsLoading,
    loadCupons,
    cupomBeingDeleted,
    cupomIdBeingDeleted,
    setDeleteModalShow,
    deleteCupom,
    deleteModalShow,
    companiesOptions,
    cupomChangingStatus,
    setChangeStatusModalShow,
    updateCupomStatus,
    changeStatusModalShow,
    handleOpenChangeStatusModal,
    selectedCompany,
    handleCompanyChange,
    cupomPlanIdBeingDeleted,
  } = useCupons();

  const hasCupons = fullCupons?.length !== 0 && !!fullCupons && !isLoading;
  const filteredListLength = filteredCupons?.length ?? 0;
  const anyResultsByFilterSelection = !filteredCupons && !isLoading && !doesListApiHasError && hasCupons;
  const isListEmpty = !doesListApiHasError && (!isLoading && fullCupons.length === 0);

  return (
    <>
      <Sidebar active="Cupons" />
      <Transitions>
        <Loader isLoading={isLoading} />

        {hasCupons && (
          <SearchAtPage
            searchTerm={searchTerm}
            onChangeSearchTerm={handleChangeSearchTerm}
            selectedCompany={selectedCompany}
            companiesList={companiesOptions}
            handleCompanyChange={handleCompanyChange}
          />
        )}

        <ListHeader
          doesListExists={hasCupons}
          hasError={doesListApiHasError}
          filteredListLength={filteredListLength}
          singularLabel="cupom"
          pluralLabel="cupons"
          onPageChange={handlePageChange}
          pagesQuantity={pagesQuantity}
          currentPage={currentPage}
          downloadExcel={downloadExcel}
          setAddCupomModalShow={setAddCupomModalShow}
        />

        {hasCupons && (
          <List
            filteredList={filteredCupons}
            handleOpenEditModal={handleOpenEditModal}
            handleOpenDeleteModal={handleOpenDeleteModal}
            handleOpenChangeStatusModal={handleOpenChangeStatusModal}
          />
        )}

        {anyResultsByFilterSelection && (
          <NoData
            icon="searchNotFound"
            label={(
              <>
                Não encontramos nenhum resultado conforme o termo de pesquisa.
              </>
  )}
          />
        )}

        {doesListApiHasError && (
          <NoData
            icon="sad"
            label={(
              <>
                Ocorreu um erro ao obter a lista dos cupons.
                <button type="button" onClick={handleTryAgain}>Tentar Novamente</button>
                .
              </>
)}
          />
        )}

        {isListEmpty && (
          <NoData
            icon="emptyBox"
            label={(
              <>
                Não há nenhum cupom cadastrado, clique no botão
                {' '}
                <strong>Novo cupom</strong>
                {' '}
                acima para cadastrar seu primeiro!
              </>
)}
          />
        )}

        <AddCupomModal
          addCupomModalShow={addCupomModalShow}
          setAddCupomModalShow={setAddCupomModalShow}
          loadCupons={loadCupons}
          setIsLoading={setIsLoading}
          companiesOptions={companiesOptions}
        />

        <EditModal
          editModalShow={editCupomModalShow}
          setEditModalShow={setEditModalShow}
          cupomObjectBeingEditted={cupomObjectBeingEditted}
          setIsLoading={setIsLoading}
          loadCupons={loadCupons}
        />

        <DeleteModal
          cupomBeingDeleted={cupomBeingDeleted}
          cupomId={cupomIdBeingDeleted}
          cupomPlanId={cupomPlanIdBeingDeleted ? Number(cupomPlanIdBeingDeleted) : null}
          setDeleteModalShow={setDeleteModalShow}
          deleteCupom={deleteCupom}
          deleteModalShow={deleteModalShow}
        />

        <ChangeStatusModal
          cupomChangingStatus={cupomChangingStatus}
          setChangeStatusModalShow={setChangeStatusModalShow}
          updateCupomStatus={updateCupomStatus}
          changeStatusModalShow={changeStatusModalShow}
        />
      </Transitions>
    </>
  );
}
