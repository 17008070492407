/* eslint-disable max-len */
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useApiCall from '../../../../../../hooks/useApiCall';
import companiesService from '../../../../../../services/companiesService';
import floatToCurrency from '../../../../../../utils/floatToCurrency';
import formatCurrency from '../../../../../../utils/formatCurrency';
import { AuthenticationTypeTypes, CupomFromApiObject, CupomObject, PlanKindTypes } from '../../types';

type LabelPixTypeLiterals = {
  [key: string]: string;
}

interface UseLoadFormInterface {
  setEmployeesQuantity: Dispatch<SetStateAction<number | ''>>;
  setElegiblesQuantity: Dispatch<SetStateAction<number | ''>>;
  setAdherenceGoal: Dispatch<SetStateAction<number | ''>>;
  setCnpj: Dispatch<SetStateAction<string>>;
  setCorporateName: Dispatch<SetStateAction<string>>;
  setFantasyName: Dispatch<SetStateAction<string>>;
  setContactName: Dispatch<SetStateAction<string>>;
  setContactCellphone: Dispatch<SetStateAction<string>>;
  setContactEmail: Dispatch<SetStateAction<string>>;
  setCep: Dispatch<SetStateAction<string>>;
  setStreetName: Dispatch<SetStateAction<string>>;
  setNumber: Dispatch<SetStateAction<number | ''>>;
  setComplement: Dispatch<SetStateAction<string>>;
  setDistrict: Dispatch<SetStateAction<string>>;
  setCity: Dispatch<SetStateAction<string>>;
  setUf: Dispatch<SetStateAction<string>>;
  setPixType: Dispatch<SetStateAction<{ value: string, label: string }>>;
  setCnpjPix: Dispatch<SetStateAction<string>>;
  setCpfPix: Dispatch<SetStateAction<string>>;
  setEmailPix: Dispatch<SetStateAction<string>>;
  setCellphonePix: Dispatch<SetStateAction<string>>;
  setRandomPix: Dispatch<SetStateAction<string>>;
  setAgency: Dispatch<SetStateAction<string>>;
  setAccount: Dispatch<SetStateAction<string>>;
  setCardholder: Dispatch<SetStateAction<string>>;
  setCnpjBank: Dispatch<SetStateAction<string>>;
  setSelectedBank: Dispatch<SetStateAction<{ value: string, label: string }>>;
  setStartDate: Dispatch<SetStateAction<string>>;
  setContractDate: Dispatch<SetStateAction<string>>;
  setMonthlyFee: Dispatch<SetStateAction<string>>;
  setLoginEmail: Dispatch<SetStateAction<string>>;
  setIsReadOnly: Dispatch<SetStateAction<boolean>>;
  setAuthenticationType: Dispatch<SetStateAction<AuthenticationTypeTypes>>;
  setAssociatedCompanies: Dispatch<SetStateAction<string[]>>;
  setKindOfCompany: Dispatch<SetStateAction<'default' | 'seller'>>;
  setCupons: Dispatch<SetStateAction<CupomObject[]>>;
  setPlanKind: Dispatch<SetStateAction<PlanKindTypes>>;
  setPlanValue: Dispatch<SetStateAction<string>>;
  setPlanTrial: Dispatch<SetStateAction<string | number>>;
  setPlanId: Dispatch<SetStateAction<number | null>>;
  setShowAtCompaniesListAppRegister: Dispatch<SetStateAction<boolean>>;
  setViewByAssociatedCompanies: Dispatch<SetStateAction<boolean>>;
}

export default function useLoadForm({
  setEmployeesQuantity,
  setElegiblesQuantity,
  setAdherenceGoal,
  setCnpj,
  setCorporateName,
  setFantasyName,
  setContactName,
  setContactCellphone,
  setContactEmail,
  setCep,
  setStreetName,
  setNumber,
  setComplement,
  setDistrict,
  setCity,
  setUf,
  setPixType,
  setCnpjPix,
  setCpfPix,
  setEmailPix,
  setCellphonePix,
  setRandomPix,
  setAgency,
  setAccount,
  setCardholder,
  setCnpjBank,
  setSelectedBank,
  setStartDate,
  setContractDate,
  setMonthlyFee,
  setAssociatedCompanies,
  setAuthenticationType,
  setIsReadOnly,
  setKindOfCompany,
  setLoginEmail,
  setCupons,
  setPlanKind,
  setPlanValue,
  setPlanTrial,
  setPlanId,
  setShowAtCompaniesListAppRegister,
  setViewByAssociatedCompanies,
}: UseLoadFormInterface) {
  const [isLoadLoading, setIsLoadLoading] = useState(false);
  const [bankOptions, setBankOptions] = useState<{ value: string, label: string }[]>([
    { value: 'Banco do Brasil S.A.', label: 'Banco do Brasil S.A.' },
    { value: 'BMG S.A.', label: 'BMG S.A.' },
    { value: 'Bradesco S.A.', label: 'Bradesco S.A.' },
    { value: 'BTG Pactual S.A.', label: 'BTG Pactual S.A.' },
    { value: 'Caixa Econômica Federal', label: 'Caixa Econômica Federal' },
    { value: 'Citibank S.A.', label: 'Citibank S.A.' },
    { value: 'HSBC Bank Brasil S.A. - Banco Múltiplo', label: 'HSBC Bank Brasil S.A. - Banco Múltiplo' },
    { value: 'Itaú BBA S.A.', label: 'Itaú BBA S.A.' },
    { value: 'Itaú Unibanco S.A.', label: 'Itaú Unibanco S.A.' },
    { value: 'J. Safra S.A.', label: 'J. Safra S.A.' },
    { value: 'Modal S.A.', label: 'Modal S.A.' },
    { value: 'Nu Pagamentos S.A', label: 'Nu Pagamentos S.A' },
    { value: 'Original S.A.', label: 'Original S.A.' },
    { value: 'PAN S.A.', label: 'PAN S.A.' },
    { value: 'Safra S.A.', label: 'Safra S.A.' },
    { value: 'Santander (Brasil) S.A.', label: 'Santander (Brasil) S.A.' },
    { value: 'SICOOB', label: 'SICOOB' },
    { value: 'Neon Pagamentos S.A', label: 'Neon Pagamentos S.A' },
    { value: 'Next Tecnologia e Serviços Digitais S.A.', label: 'Next Tecnologia e Serviços Digitais S.A.' },
    { value: 'C6 Bank', label: 'C6 Bank' },
    { value: 'Banco Inter', label: 'Banco Inter' },
  ]);

  const navigate = useNavigate();
  const { id } = useParams();
  const { apiCall } = useApiCall();

  const pixOptions = [
    { value: 'CNPJ', label: 'CNPJ' },
    { value: 'CPF', label: 'CPF' },
    { value: 'Celular', label: 'Celular' },
    { value: 'Email', label: 'E-mail' },
    { value: 'Chave', label: 'Chave aleatória' },
  ];

  const getBankOptions = useCallback(async () => {
    try {
      setIsLoadLoading(true);
      const response = await fetch('https://brasilapi.com.br/api/banks/v1');
      const json = await response.json();
      setBankOptions(json.map((bank: { name: string }) => ({ value: bank.name, label: bank.name })));
    } catch (error) {
      toast.error('Não foi possível carregar a lista de bancos. Uma lista padrão foi carregada.');
    } finally {
      setIsLoadLoading(false);
    }
  }, []);

  const getCompanyDetails = useCallback(async () => {
    const labelPixTypeMap: LabelPixTypeLiterals = {
      CNPJ: 'CNPJ',
      CPF: 'CPF',
      Celular: 'Celular',
      'E-mail': 'E-mail',
      Email: 'E-mail',
      Chave: 'Chave aleatória',
    };

    const authTypeLiterals: { [key: string]: string } = {
      cpf: 'CPF',
      email: 'E-mail',
      registrationNumber: 'Número de matrícula',
      randomCode: 'Código de identificação',
    }

    const planKindObjectLiterals: { [key: string]: { value: string, label: string }} = {
      free: { value: 'free', label: 'Acesso grátis'},
      trial: { value: 'trial', label: 'Período grátis'},
      discount: { value: 'discount', label: 'Desconto'},
      discount_no_trial: { value: 'discount_no_trial', label: 'Desconto (Sem período grátis - Cobra na hora)'},
      trial_no_subscription: { value: 'trial_no_subscription', label: 'Período de teste grátis (Sem cobrança automática)' },
      trial_discount: { value: 'trial_discount', label: 'Período grátis. Após, desconto' },
    }

    await apiCall({
      apiToCall: companiesService.getCompanyDetails,
      queryParams: { companyId: id },
      onStartLoad: () => setIsLoadLoading(true),
      onEndLoad: () => setIsLoadLoading(false),
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.erro) {
          toast.error('Não foi possível encontrar a empresa');
          navigate('/companies');
          return;
        }
        const companyData = apiResponse.details;
        setPlanId(companyData.plan_id);
        setEmployeesQuantity(companyData.funcionarios);
        setElegiblesQuantity(companyData.elegiveis);
        setAdherenceGoal(companyData.meta_adesao);
        setCnpj(companyData.cnpj);
        setCorporateName(companyData.razao_social);
        setFantasyName(companyData.nome_fantasia || companyData.nome);
        setLoginEmail(companyData.loginEmail);

        setAssociatedCompanies(companyData.associated_companies.map((x: { name: string }) => x.name));
        setAuthenticationType({ value: companyData.authentication_type, label: authTypeLiterals[companyData.authentication_type] || 'Não informado' });
        setKindOfCompany(companyData.type);
        setIsReadOnly(companyData.readOnly)

        setContactName(companyData.responsavel);
        setContactCellphone(companyData.contato_celular);
        setContactEmail(companyData.contato_email);

        setCep(companyData.endereco?.cep);
        setStreetName(companyData.endereco?.rua);
        setNumber(companyData.endereco?.numero);
        setComplement(companyData.endereco?.complemento);
        setDistrict(companyData.endereco?.bairro);
        setCity(companyData.endereco?.cidade);
        setUf(companyData.endereco?.uf);

        setPixType({ value: companyData.dados_bancarios?.tipo_pix || 'cpf', label: labelPixTypeMap[companyData.dados_bancarios?.tipo_pix] || '' });
        setCnpjPix(companyData.dados_bancarios?.tipo_pix === 'CNPJ' ? companyData.dados_bancarios?.pix : '');
        setCpfPix(companyData.dados_bancarios?.tipo_pix === 'CPF' ? companyData.dados_bancarios?.pix : '');
        setEmailPix(companyData.dados_bancarios?.tipo_pix === 'E-mail' || companyData.dados_bancarios?.tipo_pix === 'Email'  ? companyData.dados_bancarios?.pix : '');
        setCellphonePix(companyData.dados_bancarios?.tipo_pix === 'Celular' ? companyData.dados_bancarios?.pix : '');
        setRandomPix(companyData.dados_bancarios?.tipo_pix === 'Chave' ? companyData.dados_bancarios?.pix : '');
        setAgency(companyData.dados_bancarios?.agencia);
        setAccount(companyData.dados_bancarios?.conta);
        setCardholder(companyData.dados_bancarios?.titular);
        setCnpjBank(companyData.dados_bancarios?.documento);
        setSelectedBank({ value: companyData.dados_bancarios?.banco || '', label: companyData.dados_bancarios?.banco || 'Selecione um banco' });

        setStartDate(companyData.inicio);
        setContractDate(companyData.inicio_contrato_atual);
        setMonthlyFee(companyData.fee_mensal);

        setCupons(companyData.cupons?.map((cp: CupomFromApiObject) => ({
          id: cp.id,
          cupomPlanValue: floatToCurrency(cp?.planValue),
          cupomPlanTrial: cp.planTrialDays,
          cupomPlanKind: planKindObjectLiterals[cp.planKind] || { value: '', label: 'Selecione um tipo de benefício' },
          cupomName: cp.name,
        })) || []);
        setPlanKind(planKindObjectLiterals[companyData.planKind] || { value: '', label: 'Selecione um tipo de benefício' },);
        setPlanValue(formatCurrency(companyData.planValue));
        setPlanTrial(companyData.planTrialDays);

        setShowAtCompaniesListAppRegister(!!(companyData.viewByAssociatedCompanies));
        setViewByAssociatedCompanies(companyData.showAtCompaniesListAppRegister);
      },
      catchAction: () => navigate('/companies'),
      catchMessage: 'Não foi possível encontrar a empresa',
    })
  }, [apiCall, id, navigate, setAccount, setAdherenceGoal, setAgency, setAssociatedCompanies, setAuthenticationType, setCardholder, setCellphonePix, setCep, setCity, setCnpj, setCnpjBank, setCnpjPix, setComplement, setContactCellphone, setContactEmail, setContactName, setContractDate, setCorporateName, setCpfPix, setCupons, setDistrict, setElegiblesQuantity, setEmailPix, setEmployeesQuantity, setFantasyName, setIsReadOnly, setKindOfCompany, setLoginEmail, setMonthlyFee, setNumber, setPixType, setPlanId, setPlanKind, setPlanTrial, setPlanValue, setRandomPix, setSelectedBank, setShowAtCompaniesListAppRegister, setStartDate, setStreetName, setUf, setViewByAssociatedCompanies]);

  useEffect(() => {
    if (id) {
      getCompanyDetails();
    }
    getBankOptions();
  }, [getCompanyDetails, getBankOptions, id]);

  return {
    isLoadLoading,
    setIsLoadLoading,
    pixOptions,
    banksOptions: bankOptions,
  };
}
